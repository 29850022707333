import { graphql } from 'gatsby';
import Layout from 'layout/layout';
import Hero, { HeroProps } from 'blocks/hero/hero';
import React, { useEffect, useRef, useState } from 'react';
import { navigate } from '@reach/router';
import './404.scss';
import { handlePageLoaded } from 'components/trans/transLink';
import classNames from 'classnames';
import { pageContextProps } from 'utils/props.utils';
import Blocks from 'components/blocks/blocks';

interface Props {
  pageContext: pageContextProps;
  data: {
    page: {
      blocks: any[];
    };
  };
}

const NotFoundPage: React.FC<Props> = (props) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  useEffect(() => {
    const main = mainRef.current;
    !!main && imagesLoaded(document.querySelectorAll('[data-preload]'), handlePageLoaded);
    setIsLoaded(true);
  }, []);

  return (
    <Layout
      pageContext={{
        slug: '404',
        id: '404',
      }}
    >
      <div className={classNames({ 'is-loading-complete': isLoaded })}>
        <Blocks blocks={props.data.page.blocks} postFeeds={props.pageContext.postFeeds} />
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    page: wpPage(slug: { eq: "404-de" }) {
      title
      blocks {
        name
        order
        ...AnchorLinksBlock
        ...AuthorLineBlock
        ...ContactBlock
        ...DataWrapperBlock
        ...FormBlock
        ...ForschungDetailBlock
        ...GalleryBlock
        ...GraphBlock
        ...HeadingBlock
        ...HeroBlock
        ...HeroSmallBlock
        ...ImageBlock
        ...InfoBoxBlock
        ...LinksBlock
        ...ListBlock
        ...MultiColumnTextBlock
        ...PageFooterTextBlock
        ...ParagraphBlock
        ...PartnerBlock
        ...PostWithTagBlock
        ...PullQuoteBlock
        ...ReadMoreBlock
        ...SidTeasersBlock
        ...SourceBlock
        ...TeamGridBlock
        ...TeamGridSimpleBlock
        ...TopicsBlock
        ...ValuesBlock
        ...VideoIframeBlock
        ...ShareButtonsBlock
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
